import React from 'react';

function Chapter2BEN() {
	return (
    <div classname="">
      <h3>
        <strong>
          <em>Teroja Mba’éva / Adjetivos Posesivos  </em>
        </strong>
      </h3>
      <br />
      <p>Los adjetivos posesivos indican la idea de pertenencia de los sustantivos</p>
      <p>Estos pueden estar en <strong>singular</strong>: che, nde, ne, <i>índices de posesión </i> i, ij, iñ, h, hi<strong>plural</strong>: ñande. Ñane, ore, pende, pene, kuéra o nguéra.</p>
    </div>
);
}

export default Chapter2BEN;
