export const eng = {
    home: 'Home',
    profile: 'Profile',
    level: 'Level',
    levels: 'Levels',
    chapter: 'Chapter',
    chapters: 'Chapters',
    exercise: 'Exercise',
    exercises: 'Exercises',
    logout: 'Log out',
    welcome: 'Welcome',
    welcome_learned_words: 'Learned Words',
    welcome_current_progress: 'Current Progress',
    welcome_pending_chapters: 'Pending Chapters',
    welcome_news: 'News',
    welcome_start_button: 'Start',
    start: 'Start',
    next: 'Next',
    back: 'Back',
    signup: 'Sign up',
    signup_subtitle: 'This information will let us know more about you.',
    about_us: 'About us.',
    contact_us: 'Contact us',
    glossary: 'Glossary',

    username: 'Username',
    email: 'Email',
    firstname: 'First Name',
    lastname: 'Last Name',
    password: 'Password',
    re_password: 'Confirm Password',
    password_message: '8 characters minimum',
    personal_info: 'Personal Information.',
    birthdate: 'Birthdate',
    native_language: 'Native Language',
    country: 'Country',
    create_button: 'Create Account',
    login_button: 'Do you already have an account? Log in!',
    'Principiante' : 'Beginner',
    'Intermedio' : 'Intermediate',
    'Avanzado' : 'Advanced',
    toastr_message_1: 'Keep it up!',
    toastr_message_2: 'Awesome!',
    toastr_message_3: 'Excellent',
    error_title_message: 'Sorry',
    error_subtitle_message: 'The correct answer is:',
    option: 'Option',

    error_no_level:'You must select a level first.',
    congrats: 'Congratulations, keep it up!',
    restart: 'Restart',
    continue: 'Continue',

    listen_and_repeat: 'Listen and repeat:',
    listen_and_complete: 'Listen and complete:',
    listen_and_select: 'Listen and select the correct answer:',
    read_and_select: 'Read and select the correct answer:',

    complete_sentence: 'Complete the sentence:',
    select_correct_answer: 'Select the correct answer:',

    '¿Cómo se dice “hola” en Guaraní?':'How do you say “hello” in Guaraní?',
    '¿Cómo se dice “muy bien” en guaraní?':'How do you say “very good” in Guaraní?',
    '¿Cómo se dice “Mi nombre es Ana” en guaraní?': 'How do you say “My name is Ana” in Guaraní?',
    '¿Cómo se dice “Tu nombre es Ruth” en guaraní?' : 'How do you say “Your name is Ruth” in guaraní?',
}