import React from 'react';

function Chapter1DEN() {
	return (
    <div classname="">
      <h3>
        <strong>
          <em>Ore, ñande y  pe’ẽ </em>
        </strong>
      </h3>
      <br />
      <p><strong>Terarãngue – Pronombres:</strong></p>
      <p>El pronombre es la palabra que sustituye al nombre de la persona, el animal o la cosa. Se lo denomina “marca” o “sustituto".</p>
      <p><strong>Terarãnguete teroñe'ẽramo – Pronombre personal como sujeto: </strong></p>
      <p>Sustituye a las tres personas gramaticales del singular y del plural.</p>
      <br />
      <br />
      <hr />
      <p><strong>Plural:</strong> Ñande – Ore – Pe'ẽ.</p>
    </div>
	);
}

export default Chapter1DEN;
