import React from 'react';

function Chapter2AEN() {
	return (
    <div classname="">
      <h3>
        <strong>
          <em>Capítulo 2A</em>
        </strong>
      </h3>
      <br />
      <p>En esta lección aprenderemos a pluralizar un sustantivo, el nombre de algunos animales y el uso de los pronombres demostrativos.</p>
      <br />
      <h5>
        <strong>
          <em>Cómo pluralizar un sustantivo:</em>
        </strong>
      </h5>
      <br />
      <p>Una forma de pluralizar las palabras es agregando la partícula “kuéra” a los sustantivos orales: “ava<strong>kuéra</strong>”, y “nguéra” a los sustantivos nasales: “kuña<strong>nguéra</strong>”. Otra manera de pluralizar un sustantivo es agregando la partícula  “ita” a los sustantivos que terminan con una vocal fuerte: “vaka<strong>ita</strong>”, y eta a los que terminan con una vocal débil: “mboi<strong>eta</strong>”.</p>
      <br />
      <h5>
        <strong>
          <em>Pronombres demostrativos:</em>
        </strong>
      </h5>
      <br />
      <p>Los pronombres demostrativos de presencia son: “kóva, péva, amóva” para el singular y “ko’ãva, ãva, umíva” para el plural.</p>
    </div>
  );
}

export default Chapter2AEN;
