import React from 'react';

function Chapter3BEN() {
	return (
    <div classname="">
      <h3>
        <strong>
          <em>Capitulo 3A</em>
        </strong>
      </h3>
      <br />
      <p>En esta lección veremos cómo se conjugan la primera persona plural y singular de los verbos en el tiempo presente.</p>
      <p>El pronombre de la primera persona singular es “che”. La partícula que le corresponde y que va delante es “a”. Ejemplo: “Che aguata kokuépe” (Paseo por la granja).</p>
      <p>El pronombre de la primera pesona plural incluyente es “ñande”. Se lo llama así porque incluye a quienes reciben el mensaje. Utiliza la partícula prefija “ja” con los verbos orales y “ña” con los verbos nasales. Ejemplo: “Ñande jaguata” (Nosotros caminamos); “Ñande ñamaña” (Nosotros miramos).</p>
      <p>El pronombre de la primera persona plural excluyente es “ore”. Se lo llama así porque excluye a quienes reciben el mensaje. Utiliza la partícula prefija “ro” unida al verbo. Ejemplo: “Ore roguata” (Nosotros caminamos); “Ore romaña” (Nosotros miramos).</p>

    </div>
);
}

export default Chapter3BEN;
