export const esp = {
    home: 'Inicio',
    profile: 'Perfil',
    level: 'Nivel',
    levels: 'Niveles',
    chapter: 'Capítulo',
    chapters: 'Capítulos',
    exercise: 'Ejercicio',
    exercises: 'Ejercicios',
    logout: 'Cerrar Sesión',
    welcome: 'Bienvenido',
    welcome_learned_words: 'Palabras Aprendidas',
    welcome_current_progress: 'Progreso Actual',
    welcome_pending_chapters: 'Capitulos Pendientes',
    welcome_news: 'Noticias Recientes',
    welcome_start_button: 'Empecemos',
    start: 'Iniciar',
    next: 'Siguiente',
    back: 'Anterior',
    signup: 'Registrate ',
    signup_subtitle: 'Esta información nos permitirá saber más sobre ti.',
    about_us: 'Acerca de',
    contact_us: 'Contacto',
    glossary: 'Glosario',
    
    username: 'Usuario',
    email: 'Correo electrónico',
    firstname: 'Nombre',
    lastname: 'Apellido',
    password: 'Contraseña',
    re_password: 'Repetir Contraseña',
    password_message: '8 letras como minimo',
    personal_info: 'Información Personal.',
    birthdate: 'Fecha de nacimiento',
    native_language: 'Lengua materna',
    country: 'País',
    create_button: 'Crear cuenta',
    login_button: 'Ya tienes una cuenta? Inicia Sesion!',

    'Principiante': 'Principiante',
    'Intermedio': 'Intermedio',
    'Avanzado': 'Avanzado',
    toastr_message_1: 'Sigue asi!',
    toastr_message_2: 'Genial',
    toastr_message_3: 'Excelente',
    error_title_message: 'Lo siento',
    error_subtitle_message: 'La repuesta correcta es:',
    option: 'Opción',
    error_no_level:'Debes seleccionar un nivel antes para continuar.',

    congrats: 'Felicidades, sigue asi!',
    restart: 'Volver a empezar',
    continue: 'Continuar',

    listen_and_repeat: 'Escucha y repite:',
    listen_and_complete: 'Escucha y completa la oración:',
    listen_and_select: 'Escucha y selecciona la respuesta correcta:',
    read_and_select: 'Lee y selecciona la respuesta correcta:',

    complete_sentence: 'Complete la oración:',
    select_correct_answer: 'Selecciona la respuesta correcta:',

    '¿Cómo se dice “hola” en Guaraní?': '¿Cómo se dice “hola” en Guaraní?',
    
}